<template>
  <v-container id="usuarios" tag="section">
    <!-- <base-v-component heading="Data Tables" link="components/data-tables" /> -->

    <base-material-card
      color="primary"
      icon="mdi-account"
      inline
      class="px-5 py-3"
      :title="$t('users')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading> </template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn
          disabled="true"
          small
          class="ma-2"
          elevation="2"
          color="primary"
          @click.stop="dialog = true"
          >Nuevo Usuario</v-btn
        >
        <v-btn
          disabled
          small
          class="ma-2"
          elevation="2"
          color="primary"
          @click.stop="dialog2 = true"
        >
          <!-- <v-icon left x-small> mdi-email </v-icon> -->
          Invitar Usuario
        </v-btn>
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de Búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Empieza dialogo cancelar -->
      <ConfirmationDialog
        buttonColor1="red"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Empieza Dialogo nuevo usuario persistent -->
      <v-dialog v-model="dialog" max-width="500" max-height="450" persistent>
        <v-card inline title="Usuarios" class="px-2 py-2">
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->
          <v-form v-model="valid">
            <v-card-text>
              <base-subheading :subheading="formTitle" />
              <v-container>
                <v-row>
                  <!-- Nombre(s) del Usuario -->
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="Nombre(s)*"
                      maxlength="20"
                      required
                      v-model="$v.editedItem.nombre.$model"
                      :error-messages="firstNameErrors"
                      @blur="$v.editedItem.nombre.$touch()"
                      @change="onTextChange"
                      @input="$v.editedItem.nombre.$reset()"
                    ></v-text-field>
                  </v-col>

                  <!-- Apellidos del Usuario -->
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="Apellido(s)*"
                      maxlength="20"
                      required
                      v-model="$v.editedItem.apellidos.$model"
                      :error-messages="lastNameErrors"
                      @blur="$v.editedItem.apellidos.$touch()"
                      @change="onTextChange"
                      @input="$v.editedItem.apellidos.$reset()"
                    ></v-text-field>
                  </v-col>

                  <!-- Correo del Usuario -->
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="Correo Electrónico*"
                      maxlength="50"
                      required
                      v-model="$v.editedItem.correo.$model"
                      :error-messages="emailErrors"
                      @blur="$v.editedItem.correo.$touch()"
                      @change="onTextChange"
                      @input="$v.editedItem.correo.$reset()"
                    ></v-text-field>
                  </v-col>

                  <!-- Contraseña del usuario -->
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="Contraseña"
                      v-model="$v.editedItem.password.$model"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :counter="15"
                      :error-messages="passwordErrors"
                      :type="show ? 'text' : 'password'"
                      @click:append.prevent="show = !show"
                      @blur="$v.editedItem.password.$touch()"
                      @change="onTextChange"
                      @input="$v.editedItem.password.$reset()"
                    ></v-text-field>
                  </v-col>

                  <!-- Confirmación de contraseña del usuario -->
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="Confirmar Contraseña"
                      v-model="$v.editedItem.repassword.$model"
                      :append-icon="reShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :counter="15"
                      :error-messages="rePasswordErrors"
                      :type="reShow ? 'text' : 'password'"
                      @click:append.prevent="reShow = !reShow"
                      @blur="$v.editedItem.repassword.$touch()"
                      @change="onTextChange"
                      @input="$v.editedItem.repassword.$reset()"
                    ></v-text-field>
                  </v-col>

                  <!-- Rol de administrador -->
                  <!-- <v-col cols="6">
                    <v-switch dense label="Administrador" v-model="editedItem.admin"></v-switch>
                  </v-col> -->

                  <!-- Roles -->
                  <v-col cols="12">
                    <!-- <v-text-field v-model="editedItem.permisos" label="Permisos"></v-text-field> -->
                    <v-select
                      dense
                      label="Roles"
                      hint="Seleciona rol"
                      multiple
                      persistent-hint
                      small-chips
                      v-model="editedItem.roles"
                      :items="roles"
                      :menu-props="{ top: true, offsetY: true }"
                    >
                      <template #selection="{ item, parent }">
                        <v-chip color="primary" text-color="white" label small
                          ><span class="pr-2"> {{ item }}</span>
                          <v-icon x-small @click="parent.selectItem(item)">mdi-close</v-icon>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <div class="body-2 font-weight-thin">*Campo Obligatorio</div> -->
              <small>*Campo Obligatorio</small>
            </v-card-text>
          </v-form>

          <!-- Botones y checkList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              v-if="editedIndex === -1"
              v-model="checkbox"
              label="Crear otro"
            ></v-checkbox>
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="saveForm" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Empieza Dialogo2 invitar usuario persistent -->
      <v-dialog v-model="dialog2" max-width="500" max-height="450" persistent>
        <v-card inline title="Usuarios" class="px-2 py-2">
          <!-- <v-card-title>
            <span class="headline">Invitar Usuario</span>
          </v-card-title> -->
          <v-form v-model="valid">
            <v-card-text>
              <base-subheading subheading="Invitar Usuario" />
              <v-container>
                <!-- Correo electrónico del usuario a invitar -->
                <v-col>
                  <v-row cols="6" md="4">
                    <v-col>
                      <v-text-field
                        dense
                        label="Correo Electrónico*"
                        required
                        v-model="$v.editedItem.correo.$model"
                        :error-messages="emailErrors"
                        @blur="$v.editedItem.correo.$touch()"
                        @input="$v.editedItem.correo.$reset()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row cols="6" md="4">
                <v-checkbox label="Establecer contraseña" v-model="isPassword" />
              </v-row>-->
                </v-col>
                <div class="body-2 font-weight-thin">*Campo Obligatorio</div>
              </v-container>
            </v-card-text>
          </v-form>

          <!-- Botones -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="sendEmail" :disabled="$v.editedItem.correo.$invalid"
              >Aceptar</v-btn
            >
            <v-btn color="error" text @click="closeForm">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Termina Dialogo2 invitar usuario -->

      <!-- Empieza dialogo para permisos -->
      <v-dialog v-model="dialogPermisos" max-width="600">
        <v-card flat>
          <v-card-text>
            <base-subheading subheading="Asignación de permisos" />

            <base-material-tabs color="success" background-color="transparent" v-model="tab" grow>
              <v-tab v-for="sistema in sistemas" :key="sistema">
                {{ sistema }}
              </v-tab>
            </base-material-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="sistema in sistemas" :key="sistema">
                <v-card flat class="my-0">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="4" md="4">
                        <v-checkbox
                          color="success"
                          hide-details
                          v-for="modulo in infoModulos(sistema).slice(0, 4)"
                          v-model="editedItem.modulos"
                          :key="modulo._id"
                          :label="modulo.modulo"
                          :value="modulo._id"
                        ></v-checkbox>
                      </v-col>
                      <v-col v-if="infoModulos(sistema).length > 4" cols="12" sm="4" md="4">
                        <v-checkbox
                          color="success"
                          hide-details
                          v-for="modulo in infoModulos(sistema).slice(4, 8)"
                          v-model="editedItem.modulos"
                          :key="modulo._id"
                          :label="modulo.modulo"
                          :value="modulo._id"
                        ></v-checkbox>
                      </v-col>
                      <v-col v-if="infoModulos(sistema).length > 8" cols="12" sm="4" md="4">
                        <v-checkbox
                          color="success"
                          hide-details
                          v-for="modulo in infoModulos(sistema).slice(8, 12)"
                          v-model="editedItem.modulos"
                          :key="modulo._id"
                          :label="modulo.modulo"
                          :value="modulo._id"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="savePermissions">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de Usuarios -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        multi-sort
        :headers="headers"
        :loading="isLoading"
        :items="usuarios"
        :search.sync="search"
        :sort-by="['nombre']"
        :sort-desc="[false]"
      >
        <!-- Slot del avatar -->
        <template v-slot:[`item.imagen`]="{ item }">
          <ThumborImage
            tipoImagen="foto"
            v-if="item.imagen"
            :imageUrl="item.imagen.avatar"
            :avatarSize="45"
          />
          <ThumborImage v-else tipoImagen="foto" :avatarSize="45" />
        </template>

        <!-- Slot del nombre y correo -->
        <template v-slot:[`item.nombre`]="{ item }">
          <UserInfo :nombre="item.nombre" :apellidos="item.apellidos" :correo="item.correo" />
        </template>

        <!-- Slot de contacto -->
        <template v-slot:[`item.contacto`]="{ item }">
          <ContactInfo :tel="item.contacto.telefono" :cel="item.contacto.celular" />
        </template>

        <!-- Slot de login -->
        <template v-slot:[`item.login`]="{ item }">
          <SessionInfo :loginTimes="item.login.loginTimes" :lastLogin="item.login.lastLogin" />
        </template>

        <!-- Slot Conditional Icon Rol Usuario -->
        <!-- <template v-slot:[`item.admin`]="{ item }">
          <StatusInfo
            :activo="item.admin"
            txtTrue="Administrador"
            txtFalse="Usuario"
            colTrue="primary"
            colFalse="success"
          />
        </template> -->

        <template v-slot:[`item.roles`]="{ item }">
          <!-- <v-chip class="ma-2" label x-small color="blue" text-color="white">
            {{ item.permisos }}
          </v-chip> -->
          <Tags :tags="item.roles" />
        </template>

        <!-- <template #item.login_times="{item}"> <b>Conexiones:</b> {{ item.login_times }} </template> -->
        <!-- Slot de acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon disabled small class="mr-1" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon disabled small class="mr-1" @click="deleteItem(item)">mdi-delete</v-icon>
          <v-icon disabled small class="mr-1" @click="addPermissions(item)">
            mdi-account-multiple-plus
          </v-icon>
        </template>

        <!-- Slot boton de Activo-->
        <!-- <template v-slot:item.activo="{ item }">
          <Status :activo="item.activo" />
        </template> -->

        <!-- Slot de no datos -->
        <template v-slot:no-data
          ><!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!</template
        >
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import ContactInfo from '@/components/general/ContactInfo.vue'
import SessionInfo from '@/components/general/SessionInfo.vue'
// import StatusInfo from '@/components/general/StatusInfo.vue'
import Tags from '@/components/general/Tags.vue'
import ThumborImage from '@/components/general/ThumborImage.vue'
import UserInfo from '@/components/general/UserInfo.vue'

// Verifica que el password sea de 6 a 16 caracteres
const checkPassword = (value) => {
  if (!value) {
    return true
  }
  if (String(value).length < 6 || String(value).length > 16) {
    return false
  }
  return true
}

// Verifica que al menos un caracter sea número
const oneNumber = (value) => {
  if (value != '') {
    return /[0-9]/.test(value)
  } else {
    return true
  }
}

// Verifica que al menos un caracter sea mayúscula
const oneUpperCase = (value) => {
  if (value != '') {
    return /[A-Z]/.test(value)
  } else {
    return true
  }
}

// Verifica que al menos un caracter sea minúscula
const oneLowerCase = (value) => {
  if (value != '') {
    return /[a-z]/.test(value)
  } else {
    return true
  }
}

// const noWhitespace = (value) => value.indexOf(' ') === -1

export default {
  name: 'Usuarios',

  components: {
    ConfirmationDialog,
    ContactInfo,
    SessionInfo,
    // StatusInfo,
    Tags,
    ThumborImage,
    UserInfo,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    dialog2: false,
    dialogPermisos: false,
    editedIndex: -1,
    isLoading: false,
    isSure: false,
    isPassword: false,
    overlay: false,
    reShow: false,
    search: undefined,
    show: false,
    tab: null,
    valid: false,

    headers: [
      { text: 'Foto', value: 'imagen', width: '7%' },
      { text: 'Nombre', align: 'start', sortable: false, value: 'nombre' },
      { text: 'Contacto', value: 'contacto' },
      { text: 'Datos de inicio de sesión', value: 'login' },
      { text: 'Roles', value: 'roles' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    modulos: [],
    permisos: [],
    roles: ['Administrador', 'Ventas', 'Almacén'],
    sistemas: [],
    usuarios: [],
    editedItem: {
      _id: '',
      nombre: '',
      apellidos: '',
      correo: '',
      contacto: { telefono: '', celular: '' },
      modulos: [],
      password: '',
      login: '',
      repassword: '',
      roles: [],
      admin: undefined,
    },
    defaultItem: {
      _id: '',
      nombre: '',
      apellidos: '',
      correo: '',
      contacto: { telefono: '', celular: '' },
      modulos: [],
      password: '',
      login: '',
      repassword: '',
      roles: [],
      admin: undefined,
    },
  }),
  mixins: [validationMixin],
  validations: {
    editedItem: {
      nombre: { required, minLength: minLength(3), maxLength: maxLength(20) },
      apellidos: { required, minLength: minLength(3), maxLength: maxLength(20) },
      correo: { required, email },
      password: {
        checkPassword,
        oneNumber,
        oneUpperCase,
        oneLowerCase,
      },
      repassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.editedItem.nombre.$dirty) return errors
      !this.$v.editedItem.nombre.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.nombre.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.nombre.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.editedItem.apellidos.$dirty) return errors
      !this.$v.editedItem.apellidos.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.apellidos.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.apellidos.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.editedItem.correo.$dirty) return errors
      !this.$v.editedItem.correo.email && errors.push('Debe ser un correo electrónico válido')
      !this.$v.editedItem.correo.required && errors.push('El correo electrónico es requerido')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.editedItem.password.$dirty) return errors
      !this.$v.editedItem.password.checkPassword &&
        errors.push('La contraseña debe de tener un mínimo de 6 caracteres y un máximo de 15.')
      !this.$v.editedItem.password.oneNumber &&
        errors.push('La contraseña debe de tener al menos un número')
      !this.$v.editedItem.password.oneUpperCase &&
        errors.push('La contraseña debe de tener al menos una letra mayúscula')
      !this.$v.editedItem.password.oneLowerCase &&
        errors.push('La contraseña debe de tener al menos una letra minúscula')
      return errors
    },
    rePasswordErrors() {
      const errors = []
      if (!this.$v.editedItem.repassword.$dirty) return errors
      !this.$v.editedItem.repassword.sameAsPassword && errors.push('La contraseña debe ser igual.')
      return errors
    },
    infoModulos() {
      const arreglo = (sistema) => this.modulos.filter((i) => i.sistema.sistema === sistema)
      // console.log(arreglo)
      // return (sistema) => this.modulos.filter((i) => i.sistema.sistema === sistema)
      return arreglo
    },
  },
  watch: {
    dialog(val) {
      val || this.closeForm()
    },
    dialog2(val) {
      val || this.closeForm()
    },
  },

  created() {
    this.obtenerModulos()
    this.obtenerUsuarios()
  },
  methods: {
    /**
     * @description Borrar en BD no funciona por el momento
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()
      let payload = { id: this.editedItem._id }
      this.$store
        .dispatch('user/deleteUser', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se eliminó con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
            // Enviar a inicio de sesión
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir del dialogo
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.closeForm(false)
        this.changed = false
      }
    },

    /**
     * @description Verifica si cambio algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.closeForm(false)
        this.changed = false
      }
    },

    /**
     * @description Limpia todos los elementos usados en el dialogo de
     * mantenimiento de usuarios
     */
    clearForm() {
      this.$v.$reset(),
        (this.editedItem.usr_id = ''),
        (this.editedItem.nombre = ''),
        (this.editedItem.apellidos = ''),
        (this.editedItem.correo = ''),
        (this.editedItem.password = ''),
        (this.editedItem.repassword = ''),
        (this.editedItem.modulos = [])
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    closeForm(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!this.checkbox) {
        this.dialog = false
      }
      this.dialog2 = false
      this.show = false
      this.reShow = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.clearForm()
      })
      this.$v.$reset()
    },

    /**
     * @description Elimina un producto
     * @param {object} item elemento seleccionado
     */
    deleteItem(item) {
      const index = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      //Confirma cerrar formulario
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.usuarios.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo asigna al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.password = ''
      this.editedItem.repassword = ''
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de módulos
     *
     * @return {array} arreglo con todaos los módulos
     */
    async obtenerModulos() {
      this.isLoading = true
      this.$store
        .dispatch('module/fetchModules', {})
        .then((response) => {
          this.modulos = response.data
          // this.isLoading = false
          this.sistemas = [...new Set(this.modulos.map((x) => x.sistema.sistema))]
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: err,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene un listado de usuarios
     */
    async obtenerUsuarios() {
      this.isLoading = true
      this.$store
        .dispatch('user/fetchUsers', {})
        .then((response) => {
          this.usuarios = response.data
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
        })
      // }
    },

    /**
     * @description Prepara un registro para asignar los modulos a los que tendra permiso
     * el usuario y muestra el dialogo
     */
    addPermissions(item) {
      this.tab = null
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.password = ''
      this.editedItem.repassword = ''
      this.dialogPermisos = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un usuario nuevo o
     * la edicion de un usuario
     */
    async saveForm() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          nombre: this.editedItem.nombre,
          apellidos: this.editedItem.apellidos,
          correo: this.editedItem.correo,
          contacto: this.editedItem.contacto,
          modulos: this.editedItem.modulos,
          roles: this.editedItem.roles,
          admin: this.editedItem.admin,
        }
        if (this.editedItem.password !== '') {
          body.login = { password: this.editedItem.password }
        }

        if (this.editedIndex > -1) {
          // Edicion del registro
          let id = this.editedItem._id
          let payload = { id: id, body: body }
          this.$store
            .dispatch('user/editUser', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro fue actualizado con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          Object.assign(this.usuarios[this.editedIndex], this.editedItem)
        } else {
          // Registro nuevo
          let payload = { body: body }
          await this.$store
            .dispatch('user/addUser', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.usuarios.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.error,
              })
              this.overlay = false
            })
        }
        this.closeForm()
      }
    },

    /**
     * @description Guarda los permisos del usuario
     */
    async savePermissions() {
      this.overlay = true
      let body = {
        modulos: this.editedItem.modulos,
      }
      if (this.editedIndex > -1) {
        let id = this.editedItem._id
        let payload = { id: id, body: body }
        this.$store
          .dispatch('user/editUser', payload)
          .then(() => {
            this.$store.commit('ALERT', {
              color: 'success',
              text: 'Los permisos se actualizaron con éxito',
            })
            this.overlay = false
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
            this.overlay = false
          })
        Object.assign(this.usuarios[this.editedIndex], this.editedItem)
      }
      this.dialogPermisos = false
      this.clearForm()
    },

    /**
     * @description Envia una invitacion para registrarse por correo electronico
     */
    async sendEmail() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.editedItem.correo.$invalid) {
        let body = {
          correo: this.editedItem.correo,
        }
        let payload = { body: body }
        this.$store
          .dispatch('user/inviteUser', payload)
          .then(() => {
            this.$store.commit('ALERT', {
              color: 'success',
              text: 'Se ha enviado la invitación con éxito',
            })
            this.overlay = false
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
            this.overlay = false
          })
        this.closeForm()
      }
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },
  },
}
</script>
